import { config, library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faFile,
  faChartBar,
  faCog,
  faCogs,
  faUsers,
  faUsersCog,
  faBuilding,
  faPlus,
  faList,
  faSearch,
  faTimes,
  faEdit,
  faSpinner,
  faCalendar,
  faTimesCircle,
  faLightbulb,
  faTrash,
  faDesktop,
  faHammer,
  faStopwatch,
  faObjectGroup,
  faAngleRight,
  faDumpster,
  faToolbox,
  faBox,
  faPencilAlt,
  faTable,
  faIndustry,
  faFan,
  faPencilRuler,
  faPaperPlane,
  faSolarPanel,
  faExternalLinkAlt,
  faInfoCircle,
  faChevronRight,
  faBars,
  faCopy,
  faTachometerAlt,
  faCubes,
  faMapMarker,
  faCaretDown,
  faThLarge,
  faArrowUp,
  faChartLine,
  faUnlock,
  faExclamationCircle,
  faClipboardList,
  faClipboardCheck,
  faDownload,
  faUpload,
  faCheck,
  faArrowLeft,
  faBullhorn,
  faArchive,
  faImage
} from '@fortawesome/free-solid-svg-icons'

config.mutateApproach = 'sync'
config.searchPseudoElements = false
config.autoReplaceSvg = 'nest'

library.add(faFile)
library.add(faChartBar)
library.add(faCog)
library.add(faCogs)
library.add(faUsers)
library.add(faPlus)
library.add(faList)
library.add(faSearch)
library.add(faTimes)
library.add(faEdit)
library.add(faSpinner)
library.add(faCalendar)
library.add(faTimesCircle)
library.add(faLightbulb)
library.add(faTrash)
library.add(faDesktop)
library.add(faHammer)
library.add(faStopwatch)
library.add(faObjectGroup)
library.add(faAngleRight)
library.add(faDumpster)
library.add(faToolbox)
library.add(faBox)
library.add(faPencilAlt)
library.add(faPaperPlane)
library.add(faTable)
library.add(faIndustry)
library.add(faFan)
library.add(faPencilRuler)
library.add(faSolarPanel)
library.add(faExternalLinkAlt)
library.add(faInfoCircle)
library.add(faChevronRight)
library.add(faBars)
library.add(faCopy)
library.add(faUsersCog)
library.add(faBuilding)
library.add(faTachometerAlt)
library.add(faCubes)
library.add(faMapMarker)
library.add(faCaretDown)
library.add(faThLarge)
library.add(faArrowUp)
library.add(faChartLine)
library.add(faUnlock)
library.add(faExclamationCircle)
library.add(faClipboardList)
library.add(faClipboardCheck)
library.add(faDownload)
library.add(faUpload)
library.add(faCheck)
library.add(faArrowLeft)
library.add(faBullhorn)
library.add(faArchive)
library.add(faImage)

dom.watch()
